#score {
  position: fixed;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  width: 80vw;
  max-width: 300px;
  z-index: 10;
  border-radius: 20px;
  font-size: 3rem;
  background-color: var(--colors-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: color-mix(in srgb, var(--colors-black) 20%, transparent) 0px 5px 15px;
  padding: 20px 0px;

  .score-heading {
    font-size: 1.8rem;
    text-wrap: nowrap;
    margin-bottom: 10px;
  }

  section {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 0px;

    svg {
      margin-right: 20px;
      width: 50px;
      height: 50px;
    }
  }

  .score-correct {
    color: var(--colors-primary);
    svg {
      fill: var(--colors-primary);
    }
  }
  .score-wrong {
    color: var(--colors-red);
    svg {
      fill: var(--colors-red);
    }
  }

  .score-close-button {
    width: auto;
    max-width: none;
    margin: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;

    svg {
      width: 25px;
      height: 25px;
      fill: color-mix(in srgb, var(--colors-text-color) 40%, transparent);
    }
  }
}

@media (min-width: 1060px) {
  #score {
    position: absolute;
    left: -261px;
    top: auto;
    bottom: 110px;
    background-color: transparent;
    height: auto;
    width: 360px;
    max-width: 360px;
    padding: 0px 10px;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0px;
    box-shadow: none;
    pointer-events: none;
    transform: transalteX(0px);

    border: 2px solid rgb(190, 190, 190);
    border-radius: 8px;

    section {
      svg {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
    }
    .score-close-button {
      display: none;
    }
  }
}
