@import url('../shared/common.css');
@import url('../shared/score.css');
@import url('../shared/board.css');

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.game-section {
  display: flex;
  flex-flow: column;
  align-items: center;

  button {
    font-size: 2rem;
    padding: 1px 5px;
    width: 90vw;
    max-width: 600px;
    border-radius: 5px;
    background-color: var(--colors-primary);
    color: white;
    border: 2px solid var(--colors-primary);
    margin-top: 40px;
    cursor: pointer;
    transition: all 50ms ease-in-out;

    &:hover {
      background-color: var(--colors-primary-hover);
      color: var(--colors-primary);
      border-color: var(--colors-primary);
      transition: all 50ms ease-in-out;
    }
  }

  #start-stop-flip-container {
    width: 90vw;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    #flip-board-button {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #b4b4b4;
      border-color: #b4b4b4;
      svg {
        width: 40px;
      }
      &:hover {
        background-color: #dadada;
      }
    }
  }

  .prompt {
    position: absolute;
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    font-size: 2rem;
    font-family: 'OpenSans';
    font-weight: bold;
    pointer-events: none;
    cursor: none;
    color: var(--colors-text-color);
    opacity: 1;
    text-align: center;

    &.wrong {
      animation-name: wrong-mobile;
      animation-duration: 0.3s;
    }

    &.appear {
      animation-name: appear;
      animation-duration: 0.2s;
    }
  }
}

@media (min-width: 1060px) {
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  main {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    margin-top: 100px;
    width: 100%;
    max-width: 1200px;
  }

  .game-section {
    position: relative;

    #start-stop-flip-container {
      position: absolute;
      bottom: 0px;
      left: -440px;
      margin: 0px;
      width: 360px;
      max-width: 360px;
    }

    .check-position-button,
    .new-moves-game-button {
      position: absolute;
      bottom: 0px;
      left: -440px;
      margin: 0px;
      width: 360px;
    }

    .square[data-square='a1'],
    .square[data-square='h8'] {
      position: relative;
      &.show-hint {
        &::before {
          font-size: 1rem;
        }
      }
    }

    .prompt {
      font-size: 5rem;
      bottom: 80px;
      left: -440px;
      transform: translateX(0px);
      width: 360px;

      &.wrong {
        animation-name: wrong-desktop;
        animation-duration: 0.3s;
      }
    }
  }
}

@keyframes wrong-mobile {
  0% {
    transform: translateX(-50%) rotate(0deg);
    color: var(--colors-red);
  }
  25% {
    transform: translateX(-50%) rotate(10deg);
    color: var(--colors-red);
  }
  50% {
    transform: translateX(-50%) rotate(0deg);
    color: var(--colors-red);
  }
  75% {
    transform: translateX(-50%) rotate(-10deg);
    color: var(--colors-red);
  }
  100% {
    transform: translateX(-50%) rotate(0deg);
    color: var(--colors-red);
  }
}
@keyframes wrong-desktop {
  0% {
    transform: translateX(0px) rotate(0deg);
    color: var(--colors-red);
  }
  25% {
    transform: translateX(0px) rotate(10deg);
    color: var(--colors-red);
  }
  50% {
    transform: translateX(0px) rotate(0deg);
    color: var(--colors-red);
  }
  75% {
    transform: translateX(0px) rotate(-10deg);
    color: var(--colors-red);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
    color: var(--colors-red);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 2;
  }
  25% {
    opacity: 0.25;
    scale: 1.75;
  }
  50% {
    opacity: 0.5;
    scale: 1.5;
  }
  75% {
    opacity: 0.75;
    scale: 1.25;
  }
  100% {
    opacity: 1;
  }
}
