.nav-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 0;
  padding: 15px 15px 0;

  & .nav-wrapper-heading {
    width: 100%;
    margin: 0 0 20px;

    & .mobile-nav-heading {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
      font-size: 1rem;
      display: flex;

      & svg {
        fill: var(--colors-primary);
      }

      & .mobile-nav-heading-button {
        flex-direction: row;
        align-items: center;
        gap: 10px;
        display: flex;
      }

      & .mobile-nav-heading-current-mode {
        flex-direction: row;
        align-items: center;
        display: flex;

        & span {
          color: var(--colors-primary-high-contrast);
          margin-left: 5px;
          font-family: OpenSans;
        }
      }
    }
  }

  & nav {
    background-color: var(--colors-background);
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100vw;
    height: 100vh;
    transition: all .15s ease-out;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;

    &.active {
      opacity: 1;
      pointer-events: all;
      transition: all .1s ease-in;
    }

    & a {
      cursor: pointer;
      color: color-mix(in srgb, var(--colors-text-color) 80%, var(--colors-white));
      border: none;
      border-radius: 5px;
      outline: none;
      padding: 5px 15px;
      font-size: 1.6rem;
      text-decoration: none;
      transform: translateY(-40px);

      &.active {
        color: var(--colors-white);
        background-color: var(--colors-primary);
      }
    }

    & .mobile-nav-close-button {
      padding: 50px;
      position: absolute;
      bottom: 40px;
      right: 50%;
      transform: translateX(50%);

      & svg {
        opacity: .4;
        fill: var(--colors-text-color);
        width: 50px;
      }
    }
  }
}

@media (width >= 620px) {
  .nav-wrapper {
    padding: 0;
  }
}

@media (width >= 765px) {
  .nav-wrapper {
    & nav {
      & button {
        border-radius: 7px;
        padding: 10px 15px;
        font-size: 1.5rem;
      }
    }
  }
}

@media (width >= 1060px) {
  .nav-wrapper {
    flex-flow: column;
    align-items: flex-start;
    max-width: 400px;
    margin-top: 0;

    & .nav-wrapper-heading {
      font-size: 2rem;
      font-weight: medium;
      margin: 0 0 20px;
    }

    & nav {
      opacity: 1;
      pointer-events: all;
      background-color: #0000;
      flex-flow: column;
      justify-content: space-evenly;
      align-items: flex-start;
      gap: 0;
      width: 100%;
      height: auto;
      margin: 0;
      display: flex;
      position: static;

      & a {
        font-size: 1.2rem;
        transform: translate(0);

        &.active {
          color: var(--colors-primary);
          background-color: #0000;

          &:before {
            content: "‣";
            color: var(--colors-primary);
            font-size: 3rem;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
          }
        }

        &:hover {
          color: var(--colors-primary-high-contrast);

          &:before {
            content: "‣";
            color: color-mix(in srgb, var(--colors-primary) 40%, transparent);
            font-size: 3rem;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

header {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 20px 15px 10px;
  display: flex;

  & .logo-and-title-container {
    align-items: flex-end;
    gap: 10px;
    display: flex;

    & svg {
      stroke: var(--colors-text-color);
      stroke-width: 20px;
      width: 30px;
      margin-bottom: 5.5px;
      display: none;
    }

    & .title {
      font-family: Source Code Pro, monospace;
      font-size: 1.3rem;
      text-decoration: none;

      & .dotcom {
        font-family: Source Code Pro, monospace;
        font-size: .5em;
        display: none;
      }
    }
  }

  & .header-theme-and-github-container {
    flex-flow: row;
    align-items: center;
    gap: 15px;
    display: flex;

    & .theme-selector-container {
      border: 1px solid var(--colors-text-color);
      background-color: var(--colors-background);
      border-radius: 100px;
      justify-content: flex-start;
      align-items: center;
      width: 60px;
      height: 35px;
      display: flex;
      position: relative;

      & .theme-selector-svg-container {
        border-radius: 100px;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 2px;

        & svg {
          fill: var(--colors-text-color);
          width: 24px;
          position: absolute;
          top: 3px;
          left: 3px;
        }
      }
    }

    & .header-github-container {
      margin-bottom: -3px;

      & a {
        cursor: pointer;
        text-decoration: none;
      }

      & svg {
        width: 35px;

        & path {
          fill: var(--colors-text-color);
        }
      }
    }
  }
}

@media (width >= 500px) {
  header {
    & .logo-and-title-container {
      & svg {
        display: block;
      }

      & .title {
        & .dotcom {
          display: inline-block;
        }
      }
    }
  }
}

@media (width >= 765px) {
  header {
    padding: 30px 40px;

    & .title {
      font-size: 1.5rem;
    }

    & .header-theme-and-github-container {
      gap: 35px;

      & .header-github-container {
        & svg {
          width: 40px;
        }
      }
    }
  }
}

[data-theme="dark"] {
  & header {
    & .header-theme-and-github-container {
      & .theme-selector-container {
        & .theme-selector-svg-container {
          transform: translateX(25px);

          & .theme-sun {
            display: none;
          }

          & .theme-moon {
            display: block;
          }
        }
      }
    }
  }
}

[data-theme="light"] {
  & header {
    & .header-theme-and-github-container {
      & .theme-selector-container {
        & .theme-selector-svg-container {
          transform: translateX(0);

          & .theme-sun {
            display: block;
          }

          & .theme-moon {
            display: none;
          }
        }
      }
    }
  }
}

footer {
  border-top: 1px solid color-mix(in srgb, var(--colors-text-color) 30%, transparent);
  flex-flow: column;
  align-items: center;
  width: 100vw;
  margin-top: 100px;
  display: flex;

  & .footer-inner-container {
    flex-flow: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    max-width: 1400px;
    padding: 80px 0;
    display: flex;

    & section {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      display: flex;

      & h1 {
        margin-bottom: 10px;
        font-size: 1rem;
        font-weight: normal;
        text-decoration: underline;
        font-family: Source Code Pro !important;

        & a {
          font-size: 1rem;
          font-weight: normal;
          text-decoration: underline;
          font-family: Source Code Pro !important;
        }
      }

      & ul, & li, & p {
        text-wrap: nowrap;
        font-size: .9rem;
      }

      & ul {
        text-align: center;
        margin: 0;
        padding: 0;
        list-style-type: none;

        & li {
          margin-bottom: 5px;
        }
      }

      & svg {
        stroke: var(--colors-text-color);
        stroke-width: 20px;
        width: 30px;
        margin-bottom: 10px;
      }

      & a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      & .emailbtn {
        cursor: pointer;
      }
    }

    & div {
      flex-flow: column;
      align-items: center;
      gap: 50px;
      max-width: 500px;
      display: flex;
    }
  }
}

@media (width >= 765px) {
  footer {
    margin-top: 120px;

    & .footer-inner-container {
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 80px 50px;

      & section {
        align-items: flex-start;

        & ul {
          text-align: start;
        }
      }

      & .footer-sitemap, & .footer-contact {
        margin-top: 40px;
      }

      & div {
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 40%;
        display: flex;
      }
    }
  }
}

@font-face {
  font-family: OpenSans;
  src: url("OpenSans-Regular.8b824d28.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans Bold;
  src: url("OpenSans-Bold.62ac872a.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Source Code Pro;
  src: url("SourceCodePro-Regular.668f8556.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Source Code Pro;
  src: url("SourceCodePro-Bold.1a29adc3.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

:root {
  --colors-white: #fff;
  --colors-black: #000;
  --colors-yellow: #fbffd7;
  --colors-red: #cf3c3a;
  --colors-orange: #ff8330;
  --colors-primary: #429b71;
  --colors-primary-hover: color-mix(in srgb, var(--colors-primary) 10%, transparent);
  --colors-primary-high-contrast: #46815a;
  --colors-chess-board-light: #e3c16f;
  --colors-chess-board-dark: #b88b4a;
  --colors-highlight-wrong: #cf3c3a66;
  --colors-background: var(--colors-white);
  --colors-text-color: var(--colors-black);
  --colors-button-secondary: #afafaf;

  @media (prefers-color-scheme: dark) {
    & {
      --colors-yellow: #fabc2a;
      --colors-red: #ad0300;
      --colors-primary-high-contrast: var(--colors-primary);
      --colors-background: #1e1e1e;
      --colors-text-color: var(--colors-white);
    }
  }
}

[data-theme="dark"] {
  --colors-yellow: #fabc2a;
  --colors-red: #ad0300;
  --colors-primary-high-contrast: var(--colors-primary);
  --colors-background: #1e1e1e;
  --colors-text-color: var(--colors-white);
}

[data-theme="light"] {
  --colors-white: #fff;
  --colors-black: #000;
  --colors-yellow: #fbffd7;
  --colors-red: #cf3c3a;
  --colors-orange: #ff8330;
  --colors-primary: #429b71;
  --colors-primary-hover: color-mix(in srgb, var(--colors-primary) 10%, transparent);
  --colors-primary-high-contrast: #46815a;
  --colors-chess-board-light: #e3c16f;
  --colors-chess-board-dark: #b88b4a;
  --colors-highlight-wrong: #cf3c3a66;
  --colors-background: var(--colors-white);
  --colors-text-color: var(--colors-black);
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: OpenSans, sans-serif;
}

h1 {
  font-family: OpenSans, sans-serif;
  font-weight: bold;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--colors-background);
  color: var(--colors-text-color);
  overflow-x: hidden;
}

a {
  color: var(--colors-text-color);
}

.hidden {
  display: none !important;
}

.flipped {
  rotate: 180deg !important;

  & .square {
    rotate: 180deg !important;
  }

  & .square[data-square="a1"] {
    &.show-hint {
      &:before {
        transform: translateY(270%)translateX(300%) !important;
      }
    }
  }

  & .square[data-square="h8"] {
    &.show-hint {
      &:before {
        transform: translateY(270%)translateX(-300%) !important;
      }
    }
  }
}

.desktop-only {
  display: none;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

#toast {
  opacity: 1;
  background-color: var(--colors-chess-board-light);
  color: var(--colors-white);
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.3rem;
  font-weight: bold;
  transition: all .2s ease-in-out;
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%);

  &.toast-hidden {
    opacity: 0;
    transition: all .1s ease-in-out;
    transform: translate(-50%, 40px);
  }
}

.timer-switch-container {
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  display: flex;
}

@media (width >= 1060px) {
  .mobile-only {
    display: none !important;
  }

  .desktop-only {
    display: block;
  }

  #toast {
    font-size: 1.5rem;
  }

  .timer-switch-container {
    position: absolute;
    bottom: 70px;
    left: -440px;
  }
}

#score {
  z-index: 10;
  background-color: var(--colors-background);
  box-shadow: color-mix(in srgb, var(--colors-black) 20%, transparent) 0px 5px 15px;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  max-width: 300px;
  padding: 20px 0;
  font-size: 3rem;
  display: flex;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);

  & .score-heading {
    text-wrap: nowrap;
    margin-bottom: 10px;
    font-size: 1.8rem;
  }

  & section {
    flex-flow: row;
    align-items: center;
    margin-bottom: 0;
    display: flex;

    & svg {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }

  & .score-correct {
    color: var(--colors-primary);

    & svg {
      fill: var(--colors-primary);
    }
  }

  & .score-wrong {
    color: var(--colors-red);

    & svg {
      fill: var(--colors-red);
    }
  }

  & .score-close-button {
    cursor: pointer;
    background-color: #0000;
    border: none;
    border-radius: 15px;
    outline: none;
    width: auto;
    max-width: none;
    margin: 0;
    padding: 10px;
    font-size: 1rem;
    position: absolute;
    top: 0;
    right: 0;

    & svg {
      fill: color-mix(in srgb, var(--colors-text-color) 40%, transparent);
      width: 25px;
      height: 25px;
    }
  }
}

@media (width >= 1060px) {
  #score {
    box-shadow: none;
    pointer-events: none;
    transform: transalteX(0px);
    background-color: #0000;
    border: 2px solid #bebebe;
    border-radius: 8px;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0;
    width: 360px;
    max-width: 360px;
    height: auto;
    padding: 0 10px;
    position: absolute;
    top: auto;
    bottom: 110px;
    left: -261px;

    & section {
      & svg {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
    }

    & .score-close-button {
      display: none;
    }
  }
}

.board-wrapper {
  width: 100vw;
  max-width: 600px;
  height: 100vw;
  max-height: 600px;
  position: relative;

  & .board {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    & tbody {
      width: 100%;
      height: 100%;
    }

    & .squares-container {
      width: 100%;
    }

    & .rank {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      display: flex;

      & .square {
        justify-content: center;
        align-items: center;
        width: 12.5vw;
        max-width: 75px;
        height: 12.5vw;
        max-height: 75px;
        display: flex;
        position: relative;

        &.legal-target {
          &:after {
            content: "●";
            color: var(--colors-primary);
            font-size: 2.5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -55%);
          }
        }

        & .selected-to-move {
          background-color: var(--colors-red);
        }

        &:has(.selected-to-move) {
          background-color: var(--colors-red) !important;
          border: 5px solid var(--colors-red) !important;
        }

        &.selected-for-move-by-tap {
          border: 5px solid var(--colors-primary) !important;
        }

        &.wrong-position {
          background-color: red !important;
        }

        &.light {
          background-color: var(--colors-chess-board-light);
          border: 5px solid var(--colors-chess-board-light);
        }

        &.dark {
          background-color: var(--colors-chess-board-dark);
          border: 5px solid var(--colors-chess-board-dark);
        }

        &.highlight {
          border: 5px solid color-mix(in srgb, var(--colors-white) 40%, transparent);

          &:after {
            content: "";
            background-color: color-mix(in srgb, var(--colors-white) 40%, transparent);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        &.highlight-wrong {
          border: 5px solid var(--colors-highlight-wrong);

          &:after {
            content: "";
            background-color: var(--colors-highlight-wrong);
            z-index: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        &.position-highlight-wrong {
          &:after {
            content: url("mult.dbae2b89.svg");
            color: var(--colors-highlight-wrong);
            z-index: 1;
            opacity: .2;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.last-move-highlight {
          border: 5px solid color-mix(in srgb, var(--colors-white) 80%, transparent);
        }

        &.dragover {
          border: 5px solid #ff0;
        }

        & svg {
          z-index: 0;
          pointer-events: none;
          width: 100%;
          height: 100%;
        }

        & .div-around-piece-svg {
          z-index: 0;
          width: 100%;
          height: 100%;
        }

        & div {
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
    }
  }

  & #flip-board-button {
    background-color: #0000;
    border: none;
    outline-style: solid;
    outline-color: #0000;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-top: 0;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & svg {
      opacity: .2;
      width: 130px;
    }
  }
}

@media (width >= 600px) {
  .board-wrapper {
    & .board {
      & .rank {
        & .square {
          &.legal-target {
            &:after {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

@media (width >= 1060px) {
  .board-wrapper {
    & #flip-board-button {
      & svg {
        width: 180px;
      }
    }
  }
}

main {
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.game-section {
  flex-flow: column;
  align-items: center;
  display: flex;

  & button {
    background-color: var(--colors-primary);
    color: #fff;
    border: 2px solid var(--colors-primary);
    cursor: pointer;
    border-radius: 5px;
    width: 90vw;
    max-width: 600px;
    margin-top: 40px;
    padding: 1px 5px;
    font-size: 2rem;
    transition: all 50ms ease-in-out;

    &:hover {
      background-color: var(--colors-primary-hover);
      color: var(--colors-primary);
      border-color: var(--colors-primary);
      transition: all 50ms ease-in-out;
    }
  }

  & #start-stop-flip-container {
    justify-content: space-between;
    gap: 10px;
    width: 90vw;
    max-width: 600px;
    display: flex;

    & #flip-board-button {
      background-color: #b4b4b4;
      border-color: #b4b4b4;
      justify-content: center;
      align-items: center;
      width: 60px;
      display: flex;

      & svg {
        width: 40px;
      }

      &:hover {
        background-color: #dadada;
      }
    }
  }

  & .prompt {
    pointer-events: none;
    cursor: none;
    color: var(--colors-text-color);
    opacity: 1;
    text-align: center;
    font-family: OpenSans;
    font-size: 2rem;
    font-weight: bold;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);

    &.wrong {
      animation-name: wrong-mobile;
      animation-duration: .3s;
    }

    &.appear {
      animation-name: appear;
      animation-duration: .2s;
    }
  }
}

@media (width >= 1060px) {
  body {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  main {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    margin-top: 100px;
  }

  .game-section {
    position: relative;

    & #start-stop-flip-container {
      width: 360px;
      max-width: 360px;
      margin: 0;
      position: absolute;
      bottom: 0;
      left: -440px;
    }

    & .check-position-button, & .new-moves-game-button {
      width: 360px;
      margin: 0;
      position: absolute;
      bottom: 0;
      left: -440px;
    }

    & .square[data-square="a1"], & .square[data-square="h8"] {
      position: relative;

      &.show-hint {
        &:before {
          font-size: 1rem;
        }
      }
    }

    & .prompt {
      width: 360px;
      font-size: 5rem;
      bottom: 80px;
      left: -440px;
      transform: translateX(0);

      &.wrong {
        animation-name: wrong-desktop;
        animation-duration: .3s;
      }
    }
  }
}

@keyframes wrong-mobile {
  0% {
    color: var(--colors-red);
    transform: translateX(-50%)rotate(0);
  }

  25% {
    color: var(--colors-red);
    transform: translateX(-50%)rotate(10deg);
  }

  50% {
    color: var(--colors-red);
    transform: translateX(-50%)rotate(0);
  }

  75% {
    color: var(--colors-red);
    transform: translateX(-50%)rotate(-10deg);
  }

  100% {
    color: var(--colors-red);
    transform: translateX(-50%)rotate(0);
  }
}

@keyframes wrong-desktop {
  0% {
    color: var(--colors-red);
    transform: translateX(0)rotate(0);
  }

  25% {
    color: var(--colors-red);
    transform: translateX(0)rotate(10deg);
  }

  50% {
    color: var(--colors-red);
    transform: translateX(0)rotate(0);
  }

  75% {
    color: var(--colors-red);
    transform: translateX(0)rotate(-10deg);
  }

  100% {
    color: var(--colors-red);
    transform: translateX(0)rotate(0);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 2;
  }

  25% {
    opacity: .25;
    scale: 1.75;
  }

  50% {
    opacity: .5;
    scale: 1.5;
  }

  75% {
    opacity: .75;
    scale: 1.25;
  }

  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.18580a21.css.map */
