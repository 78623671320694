.board-wrapper {
  position: relative;
  width: 100vw;
  height: 100vw;
  max-width: 600px;
  max-height: 600px;

  .board {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    tbody {
      width: 100%;
      height: 100%;
    }

    .squares-container {
      width: 100%;
    }

    .rank {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      .square {
        width: calc(100vw / 8);
        height: calc(100vw / 8);
        max-width: calc(600px / 8);
        max-height: calc(600px / 8);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &.legal-target {
          &::after {
            content: '\25CF';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -55%);
            font-size: 2.5rem;
            color: var(--colors-primary);
          }
        }

        .selected-to-move {
          background-color: var(--colors-red);
        }

        &:has(.selected-to-move) {
          background-color: var(--colors-red) !important;
          border: 5px solid var(--colors-red) !important;
        }

        &.selected-for-move-by-tap {
          border: 5px solid var(--colors-primary) !important;
        }

        &.wrong-position {
          background-color: red !important;
        }

        &.light {
          background-color: var(--colors-chess-board-light);
          border: 5px solid var(--colors-chess-board-light);
        }

        &.dark {
          background-color: var(--colors-chess-board-dark);
          border: 5px solid var(--colors-chess-board-dark);
        }

        &.highlight {
          border: 5px solid color-mix(in srgb, var(--colors-white) 40%, transparent);
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: color-mix(in srgb, var(--colors-white) 40%, transparent);
          }
        }

        &.highlight-wrong {
          border: 5px solid var(--colors-highlight-wrong);
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: var(--colors-highlight-wrong);
            z-index: 0;
          }
        }

        &.position-highlight-wrong {
          &::after {
            content: url('../../images/mult.svg');
            width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: var(--colors-highlight-wrong);
            z-index: 1;
            opacity: 0.2;
          }
        }

        &.last-move-highlight {
          border: 5px solid color-mix(in srgb, var(--colors-white) 80%, transparent);
        }

        &.dragover {
          border: 5px solid yellow;
        }

        svg {
          width: 100%;
          height: 100%;
          z-index: 0;
          pointer-events: none;
        }

        .div-around-piece-svg {
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  #flip-board-button {
    background-color: transparent;
    border: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    outline-color: transparent;
    outline-style: solid;

    svg {
      width: 130px;
      opacity: 0.2;
    }
  }
}

@media (min-width: 600px) {
  .board-wrapper {
    .board {
      .rank {
        .square {
          &.legal-target {
            &::after {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1060px) {
  .board-wrapper {
    #flip-board-button {
      svg {
        width: 180px;
      }
    }
  }
}
